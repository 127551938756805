import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) 
  .init({

    resources: {
      en: {
        translation: {
          admin: "admin",
          dashboard: "Dashboard",
          orders: "Orders",
          products: "Products",
          customers: "Customers",
          employees: "Employees",
          analytics: "Analytics",
          discount: "Discount",
          inventory: "Inventory",
          logout: "Logout",
          login: "Login",
          summary: "Summary",
          thisYearOrders: "This year orders",
          thisMonthOrders: "This month orders",
          thisMonthFailedOrders: "This month failed orders",
          quickAnalysis: "Quick analysis",
          topCustomers: "Top customers",
          latestTransaction: "Latest transactions",
          customer: "Customer",
          totalSpending: "Total spending",
          totalOrders: "Total orders",
          orderID: "Order ID",
          totalPrice: "Total price",
          date: "Date",
          status: "Status",
          approved: "Approved",
          pending: "Pending",
          rejected: "Rejected",
          viewAll: "View All",
          search: "Search",
          editCustomer: "Edit customer",
          editProduct: "Edit product",
          AccountDetails: "Account details",
          contacts: "Contacts",
          edit: "Edit",
          userName: "User name",
          pass: "Password",
          phoneNumber: "Phone number",
          email: "Email",
          address: "Address",
          upload: "Upload",
          location: "Location",
          deleteCustomer: "Delete customer",
          modalMessage: "Are you sure about delete this?",
          delete: "Delete",
          cancel: "Cancel",
          actions: "Actions",
          category: "Category",
          all: "All",
          clothing: "Clothing",
          digital: "Digital",
          beauty: "Beauty",
          product: "Product",
          price: "Price",
          proName: "Product name",
          inventoryCount: "Inventory count",
          loginPage: "Login into your account",
          errorMessage: "Please enter 'admin' in user name box",
          forgetPass: "Forget your password?",
          rememberMe: "Remember me",
          salesAmount: "5,340",
          orderAmount: "3000",
          revenueAmount: "2,500",
          currency: "$",
          summaryOfSale: "Chart Of sale",
          summaryOfRevenue: "Chart of revenue",
          summaryOfOrders: "Chart of order",
          Jan: "Jan",
          Feb: "Feb",
          Mar: "Mar",
          Apr: "Apr",
          May: "May",
          Jun: "Jun",
          July: "July",
          Aug: "Aug",
          Sep: "Sep",
          Oct: "Oct",
          Nov: "Nov",
          Dec: "Dec",
          backToHome: "Back to main page",
          notFoundMsg: "Page not found!",
          ean13: "EAN 13",
          itemName: "Item name",
          supplierName: "Supplier name",
          inspectionDate: "Inspection date",
          productReference: "Product Reference",
          client: "Client",
        },
      },
      br: {
        translation: {
          admin: "administrador",
          dashboard: "Painel",
          orders: "Pedidos",
          products: "Produtos",
          customers: "Clientes",
          employees: "Funcionários",
          analytics: "Análise",
          discount: "Desconto",
          inventory: "Inventário",
          logout: "Sair",
          login: "Entrar",
          summary: "Resumo",
          thisYearOrders: "Pedidos deste ano",
          thisMonthOrders: "Pedidos deste mês",
          thisMonthFailedOrders: "Pedidos falhos deste mês",
          quickAnalysis: "Análise rápida",
          topCustomers: "Principais clientes",
          latestTransaction: "Últimas transações",
          customer: "Cliente",
          totalSpending: "Gasto total",
          totalOrders: "Total de pedidos",
          orderID: "ID do pedido",
          totalPrice: "Preço total",
          date: "Data",
          status: "Status",
          approved: "Aprovado",
          pending: "Pendente",
          rejected: "Rejeitado",
          viewAll: "Ver todos",
          search: "Buscar",
          editCustomer: "Editar cliente",
          editProduct: "Editar produto",
          AccountDetails: "Detalhes da conta",
          contacts: "Contatos",
          edit: "Editar",
          userName: "Nome de usuário",
          pass: "Senha",
          phoneNumber: "Número de telefone",
          email: "E-mail",
          address: "Endereço",
          upload: "Carregar",
          location: "Localização",
          deleteCustomer: "Excluir cliente",
          modalMessage: "Tem certeza de que deseja excluir isso?",
          delete: "Excluir",
          cancel: "Cancelar",
          actions: "Ações",
          category: "Categoria",
          all: "Todos",
          clothing: "Roupas",
          digital: "Digital",
          beauty: "Beleza",
          product: "Produto",
          price: "Preço",
          proName: "Nome do produto",
          inventoryCount: "Quantidade em inventário",
          loginPage: "Faça login na sua conta",
          errorMessage: "Por favor, insira 'admin' no campo Nome de Usuário",
          forgetPass: "Esqueceu sua senha?",
          rememberMe: "Lembrar de mim",
          salesAmount: "5.340",
          orderAmount: "3.000",
          revenueAmount: "2.500",
          currency: "R$",
          summaryOfSale: "Gráfico de vendas",
          summaryOfRevenue: "Gráfico de receita",
          summaryOfOrders: "Gráfico de pedidos",
          Jan: "Jan",
          Feb: "Fev",
          Mar: "Mar",
          Apr: "Abr",
          May: "Mai",
          Jun: "Jun",
          July: "Jul",
          Aug: "Ago",
          Sep: "Set",
          Oct: "Out",
          Nov: "Nov",
          Dec: "Dez",
          backToHome: "Voltar à página principal",
          notFoundMsg: "Página não encontrada!",
          ean13: "EAN 13",
          itemName: "Nome do item",
          supplierName: "Nome do fornecedor",
          inspectionDate: "Data de inspeção",
          productReference: "Referência do Produto",
          client: "Cliente",
        }
      },
      fr: {
        translation: {
          admin: "administrateur",
          dashboard: "Tableau de bord",
          orders: "Commandes",
          products: "Produits",
          customers: "Clients",
          employees: "Employés",
          analytics: "Analyse",
          discount: "Réduction",
          inventory: "Inventaire",
          logout: "Déconnexion",
          login: "Connexion",
          summary: "Résumé",
          thisYearOrders: "Commandes de cette année",
          thisMonthOrders: "Commandes de ce mois",
          thisMonthFailedOrders: "Commandes échouées de ce mois",
          quickAnalysis: "Analyse Rapide",
          topCustomers: "Meilleurs Clients",
          latestTransaction: "Dernières Transactions",
          customer: "Client",
          totalSpending: "Dépenses Totales",
          totalOrders: "Total des Commandes",
          orderID: "ID de Commande",
          totalPrice: "Prix Total",
          date: "Date",
          status: "Statut",
          approved: "Approuvé",
          pending: "En attente",
          rejected: "Rejeté",
          viewAll: "Voir Tout",
          search: "Recherche",
          editCustomer: "Modifier le Client",
          editProduct: "Modifier le Produit",
          AccountDetails: "Détails du Compte",
          contacts: "Contacts",
          edit: "Modifier",
          userName: "Nom d'utilisateur",
          pass: "Mot de passe",
          phoneNumber: "Numéro de Téléphone",
          email: "E-mail",
          address: "Adresse",
          upload: "Téléverser",
          location: "Emplacement",
          deleteCustomer: "Supprimer le Client",
          modalMessage: "Êtes-vous sûr de vouloir supprimer cela ?",
          delete: "Supprimer",
          cancel: "Annuler",
          actions: "Actions",
          category: "Catégorie",
          all: "Tous",
          clothing: "Vêtements",
          digital: "Numérique",
          beauty: "Beauté",
          product: "Produit",
          price: "Prix",
          proName: "Nom du Produit",
          inventoryCount: "Quantité en inventaire",
          loginPage: "Connectez-vous à votre compte",
          errorMessage: "Veuillez entrer 'admin' dans le champ Nom d'utilisateur",
          forgetPass: "Mot de passe oublié ?",
          rememberMe: "Se souvenir de moi",
          salesAmount: "5.340",
          orderAmount: "3.000",
          revenueAmount: "2.500",
          currency: "€",
          summaryOfSale: "Graphique des Ventes",
          summaryOfRevenue: "Graphique des Revenus",
          summaryOfOrders: "Graphique des Commandes",
          Jan: "Jan",
          Feb: "Fév",
          Mar: "Mar",
          Apr: "Avr",
          May: "Mai",
          Jun: "Juin",
          July: "Juil",
          Aug: "Août",
          Sep: "Sep",
          Oct: "Oct",
          Nov: "Nov",
          Dec: "Déc",
          backToHome: "Retour à la Page Principale",
          notFoundMsg: "Page Non Trouvée !",
          ean13: "EAN 13",
          itemName: "Nom de l'article",
          supplierName: "Nom du fournisseur",
          inspectionDate: "date de contrôle",
          productReference: "Référence du Produit",
          client: "Client",
        }
      },
    },
    lng: "en", 
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
